var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { flexDirection, fontSize, height, lineHeight, space, } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import { Grid, GridItem } from '../../common/components/Grid';
import { useMatchesViewport } from '../../common/hooks/media';
import { listStyle } from '../../common/theme/system-utilities';
import Arrow from './Arrow';
import Breadcrumb from './Breadcrumb';
import ListItem from './ListItem';
import { getBreadcrumbs } from './utils';
var LIST_HEIGHT = 20;
var StyledGridItem = styled(GridItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
StyledGridItem.defaultProps = {
    px: ['sp_0', 'sp_12', null, 'sp_16'],
    my: ['sp_12', 'sp_16', null, 'sp_20'],
};
var List = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), height, space, listStyle);
List.defaultProps = __assign(__assign({}, Flex.defaultProps), { as: 'ol', flexWrap: 'wrap', height: LIST_HEIGHT, m: 'sp_0', p: 'sp_0', listStyle: 'none' });
var StyledListItem = styled(ListItem)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, flexDirection);
StyledListItem.defaultProps = __assign(__assign({}, ListItem.defaultProps), { flexDirection: ['row-reverse', 'row'] });
var StyledBreadcrumb = styled(Breadcrumb)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), fontSize, lineHeight);
var Breadcrumbs = function (_a) {
    var _b = _a.matchesTabletViewport, matchesTabletViewport = _b === void 0 ? false : _b, isLastInactive = _a.isLastInactive, items = _a.items;
    var matchesTablet = useMatchesViewport(matchesTabletViewport).matches.matchesTablet;
    var breadcrumbs = getBreadcrumbs({
        items: items,
        isLastInactive: isLastInactive,
        matchesTablet: matchesTablet,
    });
    return (React.createElement(Grid, null,
        React.createElement(StyledGridItem, null,
            React.createElement(List, null, breadcrumbs.map(function (_a, index) {
                var name = _a.name, url = _a.url;
                var isLastItem = index === breadcrumbs.length - 1;
                var isDisabled = isLastInactive && isLastItem && matchesTablet;
                var isArrowNeeded = !isLastItem || !matchesTablet;
                return (React.createElement(StyledListItem, { key: index },
                    React.createElement(StyledBreadcrumb, { name: name, url: url, disabled: isDisabled }),
                    isArrowNeeded && React.createElement(Arrow, { reversed: matchesTablet })));
            })))));
};
export default Breadcrumbs;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
