var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { display, alignItems, color } from 'styled-system';
var ListItem = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), color, display, alignItems);
ListItem.defaultProps = {
    alignItems: 'center',
    display: 'inline-flex',
    color: 'sys.neutral.border.strong',
};
export default ListItem;
var templateObject_1;
