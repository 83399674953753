export var getBreadcrumbs = function (_a) {
    var items = _a.items, isLastInactive = _a.isLastInactive, matchesTablet = _a.matchesTablet;
    if (matchesTablet) {
        return items;
    }
    else if (isLastInactive) {
        return items.slice(-2, -1);
    }
    return items.slice(-1);
};
