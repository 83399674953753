var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { background, border, color, fontSize, lineHeight } from 'styled-system';
import { Link } from '../../common/components/Link';
import { getTrackerProps } from '../../common/tracking/getTrackerProps';
var BreadcrumbsLabel = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), color, fontSize, lineHeight);
BreadcrumbsLabel.defaultProps = {
    fontSize: ['fs_14', null, null, 'fs_16'],
    lineHeight: ['lh_16', null, 'lh_20'],
    color: 'sys.neutral.border.strong',
};
var BreadcrumbsLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  &:hover,\n  &:focus {\n    text-decoration: none;\n  }\n"], ["\n  ", ";\n  ", ";\n  &:hover,\n  &:focus {\n    text-decoration: none;\n  }\n"])), background, border);
BreadcrumbsLink.defaultProps = __assign(__assign({}, BreadcrumbsLabel.defaultProps), { background: 'none', border: 'none' });
var Breadcrumb = function (_a) {
    var url = _a.url, name = _a.name, disabled = _a.disabled, onClick = _a.onClick, className = _a.className;
    return disabled ? (React.createElement(BreadcrumbsLabel, { "data-testid": "breadcrumbs-label", className: className }, name)) : (React.createElement(BreadcrumbsLink, __assign({ as: url ? 'a' : 'button' }, getTrackerProps(__assign(__assign({ 'data-testid': 'breadcrumbs-link' }, (url && { href: url })), { onClick: onClick }), {
        event: 'breadcrumbsTracking',
        eventData: {
            placement: 'showroomPage',
            labelIdentifier: 'h24_breadcrumb_link',
        },
    }), { className: className }), name));
};
export default Breadcrumb;
var templateObject_1, templateObject_2;
