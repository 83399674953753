var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useMemo, useState } from 'react';
import { breakpoints } from '../theme/theme';
import { isClient } from '../utils/isClient';
export var queries = breakpoints.map(function (breakpoint) { return "(min-width: ".concat(breakpoint, ")"); });
export var useMatchesViewport = function (defaultMatchesTablet, defaultMatchesDesktop, defaultMatchesLargeDesktop) {
    if (defaultMatchesTablet === void 0) { defaultMatchesTablet = true; }
    if (defaultMatchesDesktop === void 0) { defaultMatchesDesktop = true; }
    if (defaultMatchesLargeDesktop === void 0) { defaultMatchesLargeDesktop = true; }
    var _a = __read(useState(defaultMatchesTablet), 2), matchesTablet = _a[0], setMatchesTablet = _a[1];
    var _b = __read(useState(defaultMatchesDesktop), 2), matchesDesktop = _b[0], setMatchesDesktop = _b[1];
    var _c = __read(useState(defaultMatchesLargeDesktop), 2), matchesLargeDesktop = _c[0], setMatchesLargeDesktop = _c[1];
    var _d = __read(useState(false), 2), isMounted = _d[0], setIsMounted = _d[1];
    var mediaQueries = useMemo(function () { return (isClient() ? queries.map(function (query) { return window.matchMedia(query); }) : []); }, queries);
    useEffect(function () {
        var matchesSetters = [setMatchesTablet, setMatchesDesktop, setMatchesLargeDesktop];
        mediaQueries.forEach(function (_a, index) {
            var matches = _a.matches;
            return matchesSetters[index](matches);
        });
        var listenerHandlers = matchesSetters.map(function (setter) {
            return function (_a) {
                var matches = _a.matches;
                return setter(matches);
            };
        });
        mediaQueries.forEach(function (mediaQuery, index) {
            // since addEventListener is not supported by Safari < 14, use deprecated method as a fallback
            return mediaQuery.addEventListener
                ? mediaQuery.addEventListener('change', listenerHandlers[index])
                : mediaQuery.addListener(listenerHandlers[index]);
        });
        setIsMounted(true);
        return function () {
            mediaQueries.forEach(function (mediaQuery, index) {
                return mediaQuery.removeEventListener
                    ? mediaQuery.removeEventListener('change', listenerHandlers[index])
                    : mediaQuery.removeListener(listenerHandlers[index]);
            });
            setIsMounted(false);
        };
    }, [mediaQueries]);
    return {
        matches: {
            matchesMobile: !matchesTablet && !matchesDesktop && !matchesLargeDesktop,
            matchesTablet: matchesTablet,
            matchesDesktop: matchesDesktop,
            matchesLargeDesktop: matchesLargeDesktop,
        },
        isMounted: isMounted,
    };
};
